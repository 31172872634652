import React from "react"
import { navigate } from "gatsby"
// import Header from "../components/layouts/header"

export default function NoPage() {
  typeof window !== "undefined" && navigate("/");
  return <div />
  // return (
  //   <div>
  //     <Header />
  //   </div>
  // )
}
